import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components';
import Button from '../ui/Button'
import PopupWindow from './PopupWindow';

interface Props {
  redirectUri?: string,
  clientId?: string,
  student?: boolean,
  onCode: (code: string, error: any) => void
}

export default (props: Props) => {

  const url = useMemo(() => {
    if (!props.redirectUri || !props.clientId) return null
    let params = new URLSearchParams()
    let base = `https://oauth.nightzookeeper.com/oauth/login`
    if (props.student) {
      params.set('type', 'student')
    }
    params.set('client_id', props.clientId)
    params.set('redirect_uri', props.redirectUri)
    return `${base}?${params.toString()}`
  }, [props])

  const handleLogin = useCallback(() => {
    if (!url) return null
    const popup = PopupWindow.open(
      'nzk-oauth-authorize',
      url,
      { height: 1000, width: 600 }
    )
    popup.then(
      data => props.onCode(data.code, null),
      error => props.onCode(null, error)
    )
  }, [url])

  return <Button onClick={handleLogin} size='small' theme='primary'>Sign in with Night Zookeeper</Button>
}
