interface Theme {
  backgroundColor: string;
  color: string;
  strokeColor: string;
  shadowColor: string;
}

export const THEMES: { [key: string]: Theme } = {
  primary: {
    backgroundColor: '#009EE2',
    color: '#fff',
    strokeColor: '#00C5ED',
    shadowColor: '#025899'
  },
  confirm: {
    backgroundColor: '#8CC63F',
    color: '#fff',
    strokeColor: '#AFD752',
    shadowColor: '#579800'
  },
  'the-pink': {
    backgroundColor: '#E40071',
    color: '',
    shadowColor: '',
    strokeColor: ''
  }
}

export const SIZES = {
  'x-large': 120,
  large: 92,
  regular: 64,
  small: 39
}
