import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Sidebar from "../templates/documentation/components/Sidebar"
import TableOfContent from "../templates/documentation/components/TableOfContent"

const THEME = {
  colors: {
    primary: '#8256A6'
  }
}

const Wrapper = styled.div`
  > :first-child {
    position: relative;
  }
`

const Content = styled.div`
  padding: 0 30px;
  padding-top: 30px;
  padding-left: 280px;
  transition: transform 0.5s ease 0s;
  display: flex;
  > :first-child {
    flex: 1;
    margin-right: 20px;
  }
  > :last-child {
    position: sticky;
    right: 0;
    width: 190px;
  }
`

export default ({ children }) => {
  return (
    <ThemeProvider theme={THEME}>
      <Wrapper>
        <div>
          <Sidebar />
        </div>
        <Content>
          <div>
            { children }
          </div>
        </Content>
      </Wrapper>
    </ThemeProvider>
  )
}
