import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 30px;
  width: 30px;
  border: 1px solid #71498D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Dot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #71498D;
`

interface Props {
  defaultValue?: boolean,
  onChange?: (value) => any
}

export default (props: Props) => {
  const [value, setValue] = useState(props.defaultValue)

  useEffect(() => {
    props.onChange(value)
  }, [value])

  const onChange = () => setValue(v => !v)

  return <Wrapper onClick={onChange}>
    { value && <Dot /> }
  </Wrapper>
}