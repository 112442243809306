import React, { useState } from 'react'
import LoginButton from '../../../../components/LoginButton'
import styled from 'styled-components'
import DocumentationLayout from '../../../../layouts/documentation'
import TextInput from '../../../../components/ui/TextInput'
import Button from '../../../../components/ui/Button'
import Axios from 'axios'
import Code from '../../../../components/mdx/Code'
import Checkbox from '../../../../components/ui/Checkbox'

const Wrapper = styled.div`
  
`

const Title = styled.div`
  font-size: 27px;
  font-weight: bold;
`

const Section = styled.div`
  margin: 40px 0;
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 15px;
`

const SectionContent = styled.div`
  display: flex;
  > * {
    display: flex;
    width: 100%;
    flex-direction: column;
    > * { margin-bottom: 10px; }
    > :last-child { margin-bottom: 0; }
  }
  > :first-child {
    margin-right: 30px;
  }
  > :last-child {
    max-width: 600px;
  }
`

export default () => {
  const [clientId, setClientId] = useState('a038e1816ff761c3cd057897b5271a66')
  const [redirectUri, setRedirectUri] = useState('https://dev.nightzookeeper.com/docs/oauth/sandbox')
  const [clientSecret, setClientSecret] = useState('OQYO_WOLb-h7ZGa2ZnBhCHu3')
  const [code, setCode] = useState()
  const [data, setData] = useState(null)
  const [studentLogin, setStudentLogin] = useState(true)
  const [error, setError] = useState('')

  const onCode = (code, error) => {
    if (error) return null
    if (code) setCode(code)
  }
  
  const getToken = async () => {
    const params = new URLSearchParams()
    params.append('code', code)
    params.append('grant_type', 'authorization_code')
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${window.btoa(`${clientId}:${clientSecret}`)}`
      }
    }
    try {
      const { data } = await Axios.post('https://oauth.nightzookeeper.com/oauth/token', params, config)
      setData(data)
    } catch (err) {
      if (err.response) {
        setError(err.response.data)
      } else {
        setError(err.message)
      }
    }
  }

  const getTokenString = `
  const params = new URLSearchParams()
  params.append('code', code)
  params.append('grant_type', 'authorization_code')
  const config = {
    headers: {
      'Content-Type':
        'application/x-www-form-urlencoded',
      'Authorization':
        \`Basic \${window.btoa(\`\${clientId}:\${clientSecret}\`)}\`
    }
  }
  try {
    const { data } = await Axios
      .post('https://oauth.nightzookeeper.com/oauth/token',
      params,
      config)
    setData(data)
  } catch (err) {
    if (err.response) {
      setError(err.response.data)
    } else {
      setError(err.message)
    }
  }
  `

  const params = new URLSearchParams()
  studentLogin && params.set('type', 'student')
  params.set('redirect_uri', redirectUri)
  params.set('client_id', clientId)

  const getCodeString = `
    window.open(https://oauth.nightzookeeper.com/oauth/login?${params.toString()})
  `
  
  return <DocumentationLayout><Wrapper>
    <Title>Sandbox</Title>
    <Section>
      <SectionTitle>Get code</SectionTitle>
      <SectionContent>
        <div>
          <label>Student login</label>
          <Checkbox defaultValue={studentLogin} onChange={(v) => setStudentLogin(v)} />
          <label>Client ID:</label>
          <TextInput type='text' value={clientId} onChange={e => setClientId(e.target.value)} />
          <label>Redirect URI:</label>
          <TextInput type='text' value={redirectUri} onChange={e => setRedirectUri(e.target.value)} />
          <LoginButton student={studentLogin} onCode={onCode} clientId={clientId} redirectUri={redirectUri} />
        </div>
        <div>
          { /* @ts-ignore */ }
          <Code codeString={getCodeString} />
        </div>
      </SectionContent>
    </Section>
    { code && <Section>
      <SectionTitle>Exchange code for a token</SectionTitle>
      <SectionContent>
        <div>
          <label>Code:</label>
          <TextInput type='text' value={code} />
          <div>Client secret</div>
          <TextInput type='text' value={clientSecret} onChange={e => setClientSecret(e.target.value)} />
          { clientSecret !== '' && code !== '' && <Button theme='confirm' size='small' onClick={getToken}>Exchange</Button> }
        </div>
        <div>
          { /* @ts-ignore */ }
          <Code codeString={getTokenString} />
        </div>
      </SectionContent>
    </Section> }
    { data && <Section>
      <SectionTitle>Result Data: </SectionTitle>
      <SectionContent>
        <pre id='json'>
          <code>{JSON.stringify(data, undefined, 2)}</code>
        </pre>
      </SectionContent>
    </Section> }
    { error && <Section>
      <SectionTitle>Error: </SectionTitle>
      <SectionContent>
        <pre id='json'>
          <code>{JSON.stringify(error, undefined, 2)}</code>
        </pre>
      </SectionContent>
    </Section> }
  </Wrapper></DocumentationLayout>
}